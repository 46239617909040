.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.leftnav {
  width: 22%;
  background: linear-gradient(180deg, rgba(32, 55, 69, 0.82) 0%, #162731 100%);
  height: 100vh;
}
.leftnav-inner {
  padding: 20px 0px;
}
.leftnav-inner li {
  display: flex;
  border-bottom: 1px solid #222f36;
  width: 100%;
}
.leftnav-inner a {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  width: 100%;
}
.leftnav-inner a:hover {
  background: #222f36;
  color: #fff;
}

.btn-primary {
  color: #fff;
  background: linear-gradient(184.48deg, #2af3a7 -45.19%, #02d172 96.37%);
  border-radius: 100px;
  border: 0 !important;
}
.table thead th,
tbody td {
  padding: 15px !important;
}

.site-wrapper {
  background-color: #f0f2f5;
  overflow: hidden;
  height: 100vh;
}

.main-section {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.section-layout {
  padding: 2.75rem 2.375rem;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 50px;
}

.MuiOutlinedInput-input {
  height: 16px !important;
}

.leftnav-inner .active {
  background: #222f36;
  color: #fff;
}

.typo {
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 300;
}

.count {
  margin: 0px;
  font-size: 1.5rem;
  line-height: 1.375;
  font-weight: 700;
  letter-spacing: 0.00735em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}

.date-picker {
  padding: 4px 0 5px;
  box-sizing: content-box;
  height: 10px;
  margin: 0;
  display: block;
  min-width: 0;
  padding: 16.5px 14px;
  border-radius: 4px;
  border: 0;
  font-size: 14px;
}

.border-grey {
  border-radius: 4px;
  border-style: solid;
  border-color: #e0e0e0;
  border-width: thin;
}

.react-tel-input .form-control {
  height: 45px !important;
}
.is-invalid + .country-btn {
  border-color: #dc3545 !important;
}
.MuiTablePagination-selectLabel {
  margin: 14px 0px !important;
}
.MuiTablePagination-displayedRows {
  margin: 14px 0px !important;
}

.spinning-container {
  height: 300px;
}
