.header {
  background: #364953;
  min-height: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.avatar-circle {
  width: 40px;
  height: 40px;
  background-color: #bdbdbd;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  cursor: pointer;
}

.initials {
  position: relative;
  top: 10px;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
}
